<template>
  <div>
    <Loader full :visible="isLoading" />
    <div>
      <h3 class="mb-3">{{ $t('all_news') }}</h3>
      <b-row v-if="allNews.length > 0">
        <b-col cols="12" class="my-3">
          <card-item :item="allNews[0]" size="vertical-md" has-description />
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="my-3"
          v-for="news in allNews.slice(1)"
          :key="news.id"
        >
          <card-item :item="news" />
        </b-col>
      </b-row>
      <div v-else>{{ $t('not_found') }}</div>
      <loader :size="60" :visible="true" v-if="isFetching" />
      <button
        class="button button--default button--block mt-4"
        v-else-if="newsCount > currentPage * perCount && !isFetching"
        @click="uploadMore"
      >
        <span>
          {{ $t('upload_news') }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import CardItem from '@/components/cards/CardItem'
import Loader from '@/components/Loader'

export default {
  components: {
    CardItem,
    Loader
  },
  data: () => ({
    isLoading: false,
    isFetching: false,
    perCount: 21,
    currentPage: 1
  }),
  computed: {
    allNews() {
      return this.$store.state.newsModule.news
    },
    newsCount() {
      return this.$store.state.newsModule.newsCount
    }
  },
  methods: {
    async uploadMore() {
      if (!this.isFetching) {
        this.isFetching = true
        this.currentPage++
        await this.fetchNews()
        this.isFetching = false
      }
    },
    async fetchNews() {
      await this.$store.dispatch('fetchNews', {
        _sort: 'publish_at:desc',
        _limit: this.perCount * this.currentPage
      })
    }
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      async handler() {
        this.isLoading = true
        await this.fetchNews()
        await this.$store.dispatch('fetchNewsCount')
        this.isLoading = false
      }
    }
  }
}
</script>
